import React, { useEffect, useState } from 'react';
// Utils
import useWallet from 'use-wallet';
import Web3 from 'web3';
import CONTRACTS from 'service/abi.json';
import clsx from 'clsx';
import BN from 'bn.js';
// Components
import NumberFormatField from 'components/NumberFormatField';
import {
    Box,
    Container,
    Typography,
    Button,
    TextField,
    InputAdornment,
    Hidden,
    Drawer,
    List,
    Divider,
    ListItem,
    ListItemIcon,
    ListItemText,
    CircularProgress,
    Grow,
    Grid,
} from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
// Icons
import { Menu } from '@mui/icons-material';
import { AiOutlineTwitter } from 'react-icons/ai';
import { MdDashboard } from 'react-icons/md';
import { FaDiscord, FaRoad, FaInstagram } from 'react-icons/fa';
import { RiCoinFill } from 'react-icons/ri';
import NotificationMessage from 'components/NotificationMessage';
// Images
import GGLogo from 'assets/logo.png';
import Marquee from 'react-fast-marquee';
import Dots from 'assets/images/dots.png';
import GG1 from 'assets/images/507.png';
import GG2 from 'assets/images/506.png';
import GG3 from 'assets/images/500.png';
import GG4 from 'assets/images/505.png';
import GG5 from 'assets/images/83.png';
import GG6 from 'assets/images/84.png';
import GG7 from 'assets/images/85.png';
import GG8 from 'assets/images/90.png';
import GG9 from 'assets/images/91.png';
import GG10 from 'assets/images/92.png';
import FooterLogo from 'assets/logo-written.png';
import axios from 'axios';
import Lottie from 'lottie-react';
import AnimationData from './animate-test.json';
// Styles
import useStyles from './styles';
import countdown from 'utils/countdown';

const images = [GG1, GG2, GG3, GG4, GG5, GG6, GG7, GG8, GG9, GG10];

// REAL
const whitelistCountdown = 1639540800;
const publicCountdown = 1639713605;

// FAKE
// const whitelistCountdown = 1;
// const publicCountdown = 1;

const etherscanTX = 'https://etherscan.io/tx/';

const links = [
    { name: 'Home', link: 'https://galakticgang.com', icon: MdDashboard },
    {
        name: 'Roadmap',
        link: 'https://galakticgang.com/#roadmap',
        icon: FaRoad,
    },
    {
        name: 'Utility',
        link: 'https://galakticgang.com/#utility',
        icon: RiCoinFill,
    },
];
const socials = [
    {
        name: 'Discord',
        link: 'https://discord.gg/galakticgang',
        icon: FaDiscord,
    },
    {
        name: 'Instagram',
        link: 'https://www.instagram.com/galakticgangnft',
        icon: FaInstagram,
    },
    {
        name: 'Twitter',
        link: 'https://www.twitter.com/galakticgangnft',
        icon: AiOutlineTwitter,
    },
];

export default function App() {
    const wallet = useWallet();
    const classes = useStyles();
    const refreshInterval = React.useRef(null);
    const [wlToken, setWLToken] = useState('5');
    const [pToken, setPToken] = useState('10');
    const [drawer, setDrawer] = useState(false);
    const [contracts, setContracts] = useState(null);
    const [web3, setWeb3] = useState(null);
    const [data, setData] = useState(null);
    const [purchasing, setPurchasing] = useState(false);
    const [purchasingWL, setPurchasingWL] = useState(false);
    const [whitelist, setWhitelist] = useState(null);

    const [publicCD, setPublicCD] = useState(null);
    const [whitelistCD, setWhitelistCD] = useState(null);

    useEffect(() => {
        // setTimeout(() => {
        //     if (window.ethereum?.selectedAddress) {
        //         wallet.connect('injected');
        //     }
        // }, 2500);
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            const v = countdown(publicCountdown);
            if (!v) {
                setPublicCD(publicCD);
                return clearInterval(interval);
            }

            setPublicCD(v);
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            const v = countdown(whitelistCountdown);
            if (!v) {
                setWhitelistCD(whitelistCD);
                return clearInterval(interval);
            }

            setWhitelistCD(v);
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (wallet.ethereum) setupWeb3();
    }, [wallet.ethereum]);

    async function setupWeb3() {
        if (!wallet.ethereum) return;
        const _web3 = new Web3(wallet.ethereum);
        setWeb3(_web3);
    }

    useEffect(() => {
        if (web3) connect();

        // eslint-disable-next-line
    }, [web3, wallet.account]);

    useEffect(() => {
        if (contracts) {
            if (refreshInterval.current) {
                clearInterval(refreshInterval.current);
                refreshInterval.current = null;
            }

            getWhitelist();
            getData();
            const seconds = 60;
            refreshInterval.current = setInterval(async () => {
                await getData();
            }, seconds * 1000);
        }

        return () => clearInterval(refreshInterval.current);
    }, [contracts]);

    function connect() {
        const _contracts = {};

        _contracts.galaktic = new web3.eth.Contract(
            CONTRACTS.GalakticGang.ABI,
            CONTRACTS.GalakticGang.Address[wallet.chainId],
        );

        setContracts(_contracts);
    }

    const handleConnect = () => {
        if (wallet.status === 'connected') {
            return wallet.reset();
        }
        wallet.connect('injected');
    };

    async function getWhitelist() {
        try {
            const response = await axios.get(
                `https://ey3aplwfs2.execute-api.us-east-1.amazonaws.com/Prod/galaktic/whitelist?address=${wallet.account}`,
            );

            if (response.data.signature) {
                setWhitelist({ ...response.data });
                setWLToken(response.data.amount);
            } else {
                setWhitelist(null);
            }
        } catch (error) {
            console.log(error);
        }
    }

    async function getData() {
        if (!wallet.account) return;

        const price = await contracts.galaktic.methods.price().call();
        const totalSupply = await contracts.galaktic.methods
            .totalSupply()
            .call();
        const balance = await web3.eth.getBalance(wallet.account);
        const ggBalance = await contracts.galaktic.methods
            .purchased(wallet.account)
            .call();

        setData({
            balance,
            price,
            totalSupply,
            ggBalance: parseInt(ggBalance),
        });
    }

    async function handleWhitelist(amount) {
        setPurchasingWL(true);
        try {
            const value = new BN(data.price)
                .mul(new BN(`${amount}`))
                .toString();

            const tx = await contracts.galaktic.methods
                .whitelistMint(whitelist.signature, amount, whitelist.amount)
                .send({
                    from: wallet.account,
                    value,
                });

            await getWhitelist();
            await getData();

            successToast(
                'Galaktic Gang member purchased!!',
                etherscanTX + tx.transactionHash,
            );
        } catch (error) {
            console.log(error);
            let message = error.message;
            if (message.includes('Transaction has been reverted by the EVM:')) {
                console.log('in here');
                const pError = JSON.parse(
                    error.message.replace(
                        'Transaction has been reverted by the EVM:',
                        '',
                    ),
                );
                message = await getRevertReason(pError.transactionHash);
            }
            // console.log(JSON.parse(error.message.substring(15).trim()).message);
            errorToast(message);
        }
        setPurchasingWL(false);
    }

    async function handlePurchase(amount) {
        setPurchasing(true);
        try {
            const value = new BN(data.price)
                .mul(new BN(`${amount}`))
                .toString();

            const tx = await contracts.galaktic.methods
                .publicMint(amount)
                .send({
                    from: wallet.account,
                    value,
                });
            await getData();
            successToast(
                'Galaktic Gang member purchased!!',
                etherscanTX + tx.transactionHash,
            );
        } catch (error) {
            console.log(error);
            let message = error.message;
            if (message.includes('Transaction has been reverted by the EVM:')) {
                console.log('in here');
                const pError = JSON.parse(
                    error.message.replace(
                        'Transaction has been reverted by the EVM:',
                        '',
                    ),
                );
                message = await getRevertReason(pError.transactionHash);
            }
            // console.log(JSON.parse(error.message.substring(15).trim()).message);
            errorToast(message);
        }
        setPurchasing(false);
    }

    async function getRevertReason(txHash) {
        try {
            const tx = await web3.eth.getTransaction(txHash);
            var result = await web3.eth.call(tx, tx.blockNumber);

            result = result.startsWith('0x') ? result : `0x${result}`;

            if (result && result.substr(138)) {
                const reason = web3.utils.toAscii(result.substr(138));
                return reason;
            } else {
                return 'Cannot get reason - No return value';
            }
        } catch (error) {
            return error.message.split('{')[0];
        }
    }

    function successToast(message, link) {
        toast.success(<NotificationMessage title={message} link={link} />, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,

            draggable: true,
            progress: undefined,
        });
    }

    function errorToast(message, link) {
        toast.error(<NotificationMessage title={message} link={link} />, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,

            draggable: true,
            progress: undefined,
        });
    }

    const list = (anchor) => (
        <Box
            sx={{
                width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250,
            }}
            role="presentation"
            onClick={() => setDrawer(false)}
            onKeyDown={() => setDrawer(false)}
        >
            <div className={classes.drawerLogoDiv}>
                <img src={GGLogo} className={classes.drawerLogo} />
            </div>
            <Divider />
            <List>
                {links.map((link, index) => (
                    <ListItem button key={link.name}>
                        <ListItemIcon>
                            <link.icon />
                        </ListItemIcon>
                        <ListItemText primary={link.name} />
                    </ListItem>
                ))}
            </List>
            <Divider />
            <List>
                {socials.map((social, index) => (
                    <ListItem button key={social.name}>
                        <ListItemIcon>
                            <social.icon />
                        </ListItemIcon>
                        <ListItemText primary={social.name} />
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    let now = new Date().getTime() / 1000;
    const whitelister = whitelist && now < publicCountdown;
    const whiteListOpen = now > whitelistCountdown;
    const publicOpen = now > publicCountdown;

    return (
        <div className={classes.root}>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className={classes.header}>
                <Hidden mdDown>
                    <img src={GGLogo} className={classes.logo} />
                    <div className={classes.links}>
                        <ul>
                            <li>
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://galakticgang.com/#about"
                                >
                                    About
                                </a>
                            </li>
                            <li>
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://galakticgang.com/#artwork"
                                >
                                    Artwork
                                </a>
                            </li>
                            <li>
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://galakticgang.com/#utility"
                                >
                                    Utility
                                </a>
                            </li>
                            <li>
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://galakticgang.com/#roadmap"
                                >
                                    Roadmap
                                </a>
                            </li>
                            <li>
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://galakticgang.com/#team"
                                >
                                    Team
                                </a>
                            </li>
                            <li>
                                {socials.map((social, idx) => {
                                    return (
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            key={`scidx-${idx}`}
                                            href={social.link}
                                        >
                                            <social.icon
                                                className={classes.socialIcon}
                                            />
                                        </a>
                                    );
                                })}
                            </li>
                        </ul>
                    </div>
                </Hidden>
                <Hidden mdUp>
                    <Button onClick={() => setDrawer(true)}>
                        <Menu />
                    </Button>
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        anchor={'right'}
                        open={drawer}
                        onClose={() => setDrawer(false)}
                    >
                        {list('right')}
                    </Drawer>
                </Hidden>
            </div>
            <div className={classes.navClear} />
            <div className={classes.body}>
                <Grid container spacing={6}>
                    <Grid
                        item
                        sm={12}
                        md={6}
                        classes={{ root: classes.gridAuto }}
                    >
                        {whitelister && (
                            <Grow in={true}>
                                <div>
                                    {whitelistCD && (
                                        <div className={classes.center}>
                                            <Typography variant="subtitle1">
                                                <span className={'bold'}>
                                                    Whitelist Mint Countdown
                                                </span>
                                            </Typography>
                                            <Typography variant="h5">
                                                {whitelistCD.d} Days{' '}
                                                {whitelistCD.h}:{whitelistCD.m}:
                                                {whitelistCD.s}
                                            </Typography>
                                        </div>
                                    )}

                                    <div className={clsx(classes.card)}>
                                        <Typography
                                            variant="h4"
                                            className="title"
                                            sx={{ marginBottom: 2 }}
                                        >
                                            Galaktic Gang Whitelisters
                                        </Typography>
                                        <Typography variant="subtitle1">
                                            You are on our whitelist! You may
                                            purchase two full days before the
                                            public sale! Your{' '}
                                            <span className="boldish">
                                                whitelist allocation
                                            </span>{' '}
                                            is{' '}
                                            <span className="boldish">
                                                {whitelist?.amount ?? ''}
                                            </span>
                                        </Typography>
                                        {data?.ggBalance > 0 ? (
                                            <Typography variant="subtitle1">
                                                You have purchased{' '}
                                                <span className="bold">
                                                    {data?.ggBalance}
                                                </span>{' '}
                                                you can purchase{' '}
                                                <span className="bold">
                                                    {Math.max(
                                                        whitelist?.amount -
                                                            data?.ggBalance,
                                                        0,
                                                    )}
                                                </span>{' '}
                                                more
                                            </Typography>
                                        ) : null}

                                        <div className={classes.field}>
                                            <div
                                                className={clsx(
                                                    classes.row,
                                                    classes.mb12,
                                                )}
                                            >
                                                <Typography variant="subtitle1">
                                                    Mint Price:{' '}
                                                    <span className="bold">
                                                        0.0777
                                                    </span>
                                                </Typography>

                                                <Typography variant="subtitle1">
                                                    Minted:{' '}
                                                    <span className="bold">
                                                        {data?.totalSupply ??
                                                            '?'}{' '}
                                                        / 5555
                                                    </span>
                                                </Typography>
                                            </div>
                                            <TextField
                                                id="outlined-basic"
                                                label="# of Galaktic Gang Members"
                                                variant="outlined"
                                                value={wlToken}
                                                fullWidth={true}
                                                onChange={(e) =>
                                                    setWLToken(e.target.value)
                                                }
                                                InputProps={{
                                                    inputComponent:
                                                        NumberFormatField,
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <Button
                                                                disabled={
                                                                    purchasingWL ||
                                                                    !whiteListOpen
                                                                }
                                                                onClick={() =>
                                                                    handleWhitelist(
                                                                        wlToken,
                                                                    )
                                                                }
                                                            >
                                                                {purchasingWL && (
                                                                    <CircularProgress
                                                                        size={
                                                                            24
                                                                        }
                                                                        className={
                                                                            classes.loader
                                                                        }
                                                                    />
                                                                )}
                                                                Purchase
                                                            </Button>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                            <Typography variant="subtitle2">
                                                Your available Ethereum:{' '}
                                                {parseFloat(
                                                    data?.balance?.web3Readable() ??
                                                        0,
                                                ).numberWithCommas(5)}
                                            </Typography>
                                        </div>
                                        {wallet.status === 'connected' ? (
                                            <>
                                                <div
                                                    className={classes.actions}
                                                >
                                                    <Button
                                                        onClick={() =>
                                                            handleWhitelist(
                                                                wlToken,
                                                            )
                                                        }
                                                        disabled={
                                                            purchasingWL ||
                                                            !whiteListOpen
                                                        }
                                                        sx={{
                                                            marginTop: 1,
                                                        }}
                                                        variant="contained"
                                                    >
                                                        Purchase
                                                    </Button>
                                                </div>
                                            </>
                                        ) : (
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handleConnect}
                                            >
                                                Connect Metamask
                                            </Button>
                                        )}
                                        {wallet.status === 'connected' && (
                                            <Typography
                                                variant="body1"
                                                sx={{ marginTop: 3 }}
                                            >
                                                Connected with:{' '}
                                                <a
                                                    href={`https://etherscan.io/address/${wallet.account}`}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <span className="bold">
                                                        {wallet?.account?.shortenAddress?.()}
                                                    </span>
                                                </a>
                                            </Typography>
                                        )}
                                    </div>
                                </div>
                            </Grow>
                        )}
                        {!whitelister && (
                            <Grow in={true}>
                                <div>
                                    {publicCD && (
                                        <div className={classes.center}>
                                            <Typography variant="subtitle1">
                                                <span className={'bold'}>
                                                    Public Mint Countdown
                                                </span>
                                            </Typography>
                                            <Typography variant="h5">
                                                {publicCD.d} Days {publicCD.h}:
                                                {publicCD.m}:{publicCD.s}
                                            </Typography>
                                        </div>
                                    )}
                                    <div className={clsx(classes.card)}>
                                        <Typography
                                            variant="h4"
                                            className="title"
                                            sx={{ marginBottom: 2 }}
                                        >
                                            Purchase your Galaktic
                                            <br />
                                            Gang Member
                                        </Typography>
                                        <Typography variant="subtitle1">
                                            You may purchase up to 10 at at time
                                            and 10 per wallet.
                                        </Typography>
                                        {data?.ggBalance > 0 ? (
                                            <Typography variant="subtitle1">
                                                You have purchased{' '}
                                                <span className="bold">
                                                    {data?.ggBalance}
                                                </span>{' '}
                                            </Typography>
                                        ) : null}
                                        <div className={classes.field}>
                                            <div
                                                className={clsx(
                                                    classes.row,
                                                    classes.mb12,
                                                )}
                                            >
                                                <Typography variant="subtitle1">
                                                    Mint Price:{' '}
                                                    <span className="bold">
                                                        0.0777
                                                    </span>
                                                </Typography>

                                                <Typography variant="subtitle1">
                                                    Minted:{' '}
                                                    <span className="bold">
                                                        {data?.totalSupply ??
                                                            '?'}{' '}
                                                        / 5555
                                                    </span>
                                                </Typography>
                                            </div>
                                            <TextField
                                                id="outlined-basic"
                                                label="# of nfts"
                                                variant="outlined"
                                                value={pToken}
                                                onChange={(e) =>
                                                    setPToken(e.target.value)
                                                }
                                                InputProps={{
                                                    inputComponent:
                                                        NumberFormatField,
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <Button
                                                                disabled={
                                                                    purchasing ||
                                                                    !wallet.account ||
                                                                    !publicOpen
                                                                }
                                                                onClick={() =>
                                                                    handlePurchase(
                                                                        pToken,
                                                                    )
                                                                }
                                                            >
                                                                {purchasing && (
                                                                    <CircularProgress
                                                                        size={
                                                                            24
                                                                        }
                                                                        className={
                                                                            classes.loader
                                                                        }
                                                                    />
                                                                )}
                                                                Purchase
                                                            </Button>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                            <Typography variant="subtitle2">
                                                Your available Ethereum:{' '}
                                                {parseFloat(
                                                    data?.balance?.web3Readable() ??
                                                        0,
                                                ).numberWithCommas(5)}
                                            </Typography>
                                        </div>
                                        {wallet.status === 'connected' ? (
                                            <>
                                                <Typography
                                                    variant="h5"
                                                    sx={{ marginBottom: 2 }}
                                                >
                                                    Or select an amount to mint
                                                </Typography>
                                                <div
                                                    className={classes.actions}
                                                >
                                                    <Button
                                                        onClick={() => {
                                                            setPToken(1);
                                                            handlePurchase(1);
                                                        }}
                                                        disabled={
                                                            purchasing ||
                                                            !publicOpen
                                                        }
                                                        sx={{
                                                            marginRight: {
                                                                md: 2,
                                                            },
                                                            marginBottom: {
                                                                sm: 2,
                                                                xs: 2,
                                                                md: 0,
                                                            },
                                                        }}
                                                        variant="contained"
                                                    >
                                                        Mint 1
                                                    </Button>
                                                    <Button
                                                        onClick={() => {
                                                            setPToken(3);
                                                            handlePurchase(3);
                                                        }}
                                                        disabled={
                                                            purchasing ||
                                                            !publicOpen
                                                        }
                                                        sx={{
                                                            marginLeft: {
                                                                md: 2,
                                                            },
                                                            marginRight: {
                                                                md: 2,
                                                            },
                                                            marginBottom: {
                                                                sm: 2,
                                                                xs: 2,
                                                                md: 0,
                                                            },
                                                        }}
                                                        variant="contained"
                                                    >
                                                        Mint 3
                                                    </Button>{' '}
                                                    <Button
                                                        onClick={() => {
                                                            setPToken(5);
                                                            handlePurchase(5);
                                                        }}
                                                        disabled={
                                                            purchasing ||
                                                            !publicOpen
                                                        }
                                                        sx={{
                                                            marginLeft: {
                                                                md: 2,
                                                            },
                                                            marginRight: {
                                                                md: 2,
                                                            },
                                                            marginBottom: {
                                                                sm: 2,
                                                                xs: 2,
                                                                md: 0,
                                                            },
                                                        }}
                                                        variant="contained"
                                                    >
                                                        Mint 5
                                                    </Button>{' '}
                                                    <Button
                                                        onClick={() => {
                                                            setPToken(10);
                                                            handlePurchase(10);
                                                        }}
                                                        disabled={
                                                            purchasing ||
                                                            !publicOpen
                                                        }
                                                        sx={{
                                                            marginLeft: {
                                                                sm: 1,
                                                            },
                                                            marginBottom: {
                                                                sm: 2,
                                                                xs: 2,
                                                                md: 0,
                                                            },
                                                        }}
                                                        variant="contained"
                                                    >
                                                        Mint 10
                                                    </Button>{' '}
                                                </div>
                                            </>
                                        ) : (
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handleConnect}
                                            >
                                                Connect Metamask
                                            </Button>
                                        )}
                                        {wallet.status === 'connected' && (
                                            <Typography
                                                variant="body1"
                                                sx={{ marginTop: 3 }}
                                            >
                                                Connected with:{' '}
                                                <a
                                                    href={`https://etherscan.io/address/${wallet.account}`}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <span className="bold">
                                                        {wallet?.account?.shortenAddress?.()}
                                                    </span>
                                                </a>
                                            </Typography>
                                        )}
                                    </div>
                                    <Typography variant="subtitle2">
                                        Whitelisted? Connect your wallet to see
                                        whitelist interface
                                    </Typography>
                                </div>
                            </Grow>
                        )}
                    </Grid>
                    <Hidden mdDown>
                        <Grid item sm={12} md={6} sx={{ marginBottom: 2 }}>
                            <Lottie animationData={AnimationData} />
                            {/* <div className={classes.images}>
                                <img src={Group10} />
                            </div> */}
                        </Grid>
                    </Hidden>
                </Grid>
            </div>
            <Hidden mdUp>
                <Marquee gradient={false} className={classes.marquee}>
                    {images.map((image, idx) => {
                        return (
                            <img
                                key={`m-${idx}`}
                                src={image}
                                className={classes.image}
                            />
                        );
                    })}
                </Marquee>
            </Hidden>
            <Container maxWidth={'md'} sx={{ paddingBottom: 8 }}>
                <div className={classes.disclaimer}>
                    <Typography variant="subtitle2">
                        <span className="bold">DISCLAIMER: </span>Upon purchase
                        of a Galaktic Gang Member, you 100% own the content and
                        art of the specific member in which you were minted or
                        bought during secondary sale. The ownership transfers as
                        the sale takes place on an NFT Marketplace.
                    </Typography>
                </div>
            </Container>
            <div className={classes.footer}>
                <img src={Dots} className={classes.dots} />
                <div>
                    <img src={FooterLogo} />
                    <Typography variant="h5" sx={{ marginBottom: 2 }}>
                        <span className={'underline'}>
                            5555 Positive Freaks n Weirdo
                        </span>{' '}
                        to be recruited
                    </Typography>
                    <Typography variant="subtitle" className={'underline'}>
                        @2021 Chris Dyer NFT. All Rights Reserved
                    </Typography>
                </div>
                <div className={classes.footerLinks}>
                    <ul>
                        <li>
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href="https://galakticgang.com/#about"
                            >
                                About
                            </a>
                        </li>
                        <li>
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href="https://galakticgang.com/#artwork"
                            >
                                Artwork
                            </a>
                        </li>
                        <li>
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href="https://galakticgang.com/#utility"
                            >
                                Utility
                            </a>
                        </li>
                        <li>
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href="https://galakticgang.com/#roadmap"
                            >
                                Roadmap
                            </a>
                        </li>
                        <li>
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href="https://galakticgang.com/#team"
                            >
                                Team
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}
