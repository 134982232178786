import { makeStyles } from '@mui/styles';
import BG from 'assets/images/background.jpeg';

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100vh',
        width: '100%',
        backgroundImage: `url(${BG})`,
        backgroundSize: 'cover',
        backgroundColor: 'black',
    },
    body: {
        maxWidth: 1500,
        margin: 'auto',
        padding: '0px 60px',
        textAlign: 'center',
        minHeight: '90vh',

        [theme.breakpoints.down('lg')]: {
            padding: '0px 0px',
        },
    },
    header: {
        padding: '2px 6px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        zIndex: 5,
        position: 'fixed',
        top: 0,
        background: 'rgba(0, 0, 0 , 0.85)',
        transition: 'all 0.3s ease',
        '-webkit-transition': 'all 0.3s ease',
    },
    links: {
        '& > ul': {
            listStyle: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',

            '& > li': {
                margin: '0px 18px',
                cursor: 'pointer',
                fontWeight: '700',
                fontSize: 16,
                textTransform: 'uppercase',
            },
            '& > li:list-child': {
                marginRight: 0,
            },
        },
    },
    navClear: {
        height: 120,
        [theme.breakpoints.down('sm')]: {
            height: 60,
        },
    },
    logo: {
        width: 120,
    },
    socialIcon: {
        marginRight: 12,
        fontSize: 24,
        marginTop: 6,
        // color: theme.palette.primary.secondary,
    },
    menuItem: {
        margin: '12px 0px !important',
    },
    card: {
        textAlign: 'center',
        padding: 20,
        borderRadius: 20,
        backdropFilter: 'blur(6px)',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        boxShadow: '0 2px 30px rgba(0, 0, 0, 0.27)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        transition: 'all .3s ease',
    },
    cardGrid: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    drawerPaper: {
        backgroundImage: 'none !important',
    },
    drawerLogo: {
        width: '90%',
    },
    drawerLogoDiv: {
        padding: '24px 0px 12px 10px',
    },
    actions: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        // flexWrap: 'wrap',

        '& button:nth-child(2)': {
            marginRight: 5,
            marginLeft: 5,
        },
    },
    field: {
        '& > div': {
            width: '100%',
        },
        width: '80%',
        margin: '12px 0px',
        textAlign: 'left',

        [theme.breakpoints.down('sm')]: {
            width: '95%',
        },
    },
    disclaimer: {
        textAlign: 'center',
    },
    loader: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: '-12px',
        marginLeft: '-12px',
    },
    stack: {
        color: theme.palette.primary.main,
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    mb12: {
        marginBottom: 12,
    },
    images: {
        marginTop: 16,
    },
    image: {
        width: 240,
        height: 288,
    },
    marquee: {
        margin: '0px 0px 24px',
        '& img': {
            marginRight: 12,
        },
    },

    /** Footer */
    dots: {
        position: 'absolute',
        width: '100%',
        top: -50,
        left: 0,
        objectFit: 'cover',
        minHeight: 71,
    },
    footer: {
        position: 'relative',
        width: '100%',
        backgroundColor: 'black',
        padding: '57px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',

        '& ul': {
            listStyle: 'none',

            '& li': {
                marginTop: 8,
            },
        },

        [theme.breakpoints.down('sm')]: {
            '& > div:first-child': {
                textAlign: 'center',
            },

            flexDirection: 'column',

            '& ul': {
                marginTop: 24,
                listStyle: 'none',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexWrap: 'wrap',
                paddingLeft: 0,

                '& > li': {
                    margin: '0px 18px',
                    cursor: 'pointer',
                    fontWeight: '700',
                    fontSize: 16,
                    textTransform: 'uppercase',
                },
                '& > li:list-child': {
                    marginRight: 0,
                },
            },
        },
    },

    videoContainer: {
        background: 'black',
        position: 'fixed',
        top: 0,
        zIndex: 2,
        width: '100%',
        height: '100%',
    },
    video: {
        width: '100%',
        height: '100%',
    },

    gadgetImage: {
        height: '100%',
        width: '100%',
    },
    center: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

export default useStyles;
