// icons
import { AiOutlineTwitter } from 'react-icons/ai';
import { MdDashboard } from 'react-icons/md';
import { FaDiscord, FaRoad, FaInstagram } from 'react-icons/fa';
import { RiCoinFill } from 'react-icons/ri';
// Previews
import Board1Img from 'assets/previews/Board1_Preview_Transparent.png';
import Board2Img from 'assets/previews/Board2_Preview_Transparent.png';
import WallImg from 'assets/previews/Wall_Preview_Transparent.png';
import PortalMiddleImg from 'assets/previews/PortalRight_Preview_Transparent.png';
import PaintCanImg from 'assets/previews/PaintCan_Preview_Transparent.png';
import BoomboxImg from 'assets/previews/Boombox_Preview_Transparent.png';

const Board1Model = 'models/board1.glb';
const Board2Model = 'models/board2.glb';
const WallModel = 'models/wall.glb';
const PortalModel = 'models/portal.glb';
const PaintCanModel = 'models/paintcan.glb';
const BoomboxModel = 'models/boombox.glb';

export const etherscanTX = 'https://etherscan.io/tx/';
export const links = [
    { name: 'Home', link: 'https://galakticgang.com', icon: MdDashboard },
    {
        name: 'Roadmap',
        link: 'https://galakticgang.com/#roadmap',
        icon: FaRoad,
    },
    {
        name: 'Utility',
        link: 'https://galakticgang.com/#utility',
        icon: RiCoinFill,
    },
];
export const socials = [
    {
        name: 'Discord',
        link: 'https://discord.gg/galakticgang',
        icon: FaDiscord,
    },
    {
        name: 'Instagram',
        link: 'https://www.instagram.com/galakticgangnft',
        icon: FaInstagram,
    },
    {
        name: 'Twitter',
        link: 'https://www.twitter.com/galakticgangnft',
        icon: AiOutlineTwitter,
    },
];

export const gadgets = {
    paintcan: {
        id: 0,
        name: 'Eye Can',
        image: PaintCanImg,
        cost: 1,
        object: {
            file: PaintCanModel,
            props: {
                scale: 0.5,
            },
            camera: { position: [15, 0, 0], fov: 6 },
        },
    },
    board1: {
        id: 1,
        name: 'DM Tricksters Deck',
        cost: 2,
        image: Board2Img,
        object: {
            file: Board1Model,
            props: {
                scale: 1.4,
                rotation: [0, Math.PI / 2, 0],
            },
            camera: { position: [15, 0, 0], fov: 2 },
        },
    },
    board2: {
        id: 2,
        name: 'OG Deck',
        cost: 2,
        image: Board1Img,
        object: {
            file: Board2Model,
            props: {
                scale: 1.4,
                rotation: [0, Math.PI / 2, 0],
            },
            camera: { position: [15, 0, 0], fov: 2 },
        },
    },
    wall: {
        id: 3,
        name: 'Alchemist Piece',
        image: WallImg,
        cost: 3,
        object: {
            file: WallModel,
            props: {
                rotation: [0, -Math.PI / 2, 0],
            },
            camera: { fov: 2, zoom: 0.1 },
        },
    },
    boombox: {
        id: 4,
        name: 'Beastie Boombox',
        image: BoomboxImg,
        cost: 3,
        object: {
            file: BoomboxModel,
            props: {
                rotation: [Math.PI / 16, Math.PI / 8, 0],
            },
            camera: { fov: 4, zoom: 0.1 },
        },
    },
    portal: {
        id: 5,
        name: 'Positive Portal',
        image: PortalMiddleImg,
        cost: 5,
        object: {
            file: PortalModel,
            props: {
                scale: 0.01,
                rotation: [Math.PI / 16, Math.PI / 6, 0],
            },
            camera: { fov: 4 },
        },
    },
};
