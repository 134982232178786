import React, { useEffect, useState } from 'react';
// Utils
import useWallet from 'use-wallet';
import Web3 from 'web3';
import CONTRACTS from 'service/abi.json';
import clsx from 'clsx';
import { isMobile } from 'react-device-detect';
import {
    Box,
    Typography,
    Button,
    Hidden,
    Drawer,
    List,
    Divider,
    ListItem,
    ListItemIcon,
    ListItemText,
    Grow,
    Grid,
} from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
// Icons
import { Menu } from '@mui/icons-material';
import { AiOutlineTwitter } from 'react-icons/ai';
import { MdDashboard } from 'react-icons/md';
import { FaDiscord, FaRoad, FaInstagram } from 'react-icons/fa';
import { RiCoinFill } from 'react-icons/ri';
import NotificationMessage from 'components/NotificationMessage';
// Images
import GGLogo from 'assets/logo.png';
import Dots from 'assets/images/dots.png';
import Slice from 'assets/anniversary-slice.png';
import FooterLogo from 'assets/logo-written.png';
// Styles
import useStyles from './styles';
import countdown from 'utils/countdown';

import Audio from 'assets/congo.mp3';
const etherscanTX = 'https://etherscan.io/tx/';

const links = [
    { name: 'Home', link: 'https://galakticgang.com', icon: MdDashboard },
    {
        name: 'Roadmap',
        link: 'https://galakticgang.com/#roadmap',
        icon: FaRoad,
    },
    {
        name: 'Utility',
        link: 'https://galakticgang.com/#utility',
        icon: RiCoinFill,
    },
];
const socials = [
    {
        name: 'Discord',
        link: 'https://discord.gg/galakticgang',
        icon: FaDiscord,
    },
    {
        name: 'Instagram',
        link: 'https://www.instagram.com/galakticgangnft',
        icon: FaInstagram,
    },
    {
        name: 'Twitter',
        link: 'https://www.twitter.com/galakticgangnft',
        icon: AiOutlineTwitter,
    },
];

const SHOW_AT = 1660268160 - 60;
// const SHOW_AT = 1660195301 + 30;

export default function FreeMint() {
    const wallet = useWallet();
    const classes = useStyles();
    const audioRef = React.useRef(null);
    const refreshInterval = React.useRef(null);
    const cdIntervals = React.useRef({});
    const [drawer, setDrawer] = useState(false);
    const [contracts, setContracts] = useState(null);
    const [web3, setWeb3] = useState(null);
    const [data, setData] = useState(null);
    const [publicCD, setPublicCD] = useState(null);
    const [showCD, setShowCD] = useState(null);

    const [minting, setMinting] = useState(false);

    useEffect(() => {
        if (audioRef.current) audioRef.current.volume = 0.3;
    }, [audioRef.current]);

    function playMusic() {
        audioRef?.current?.play();
    }

    useEffect(() => {
        if (wallet.ethereum) setupWeb3();
    }, [wallet.ethereum]);

    useEffect(() => {
        const now = new Date().getTime() / 1000;
        if (now < SHOW_AT) {
            if (cdIntervals?.current?.show)
                clearInterval(cdIntervals?.current?.show);

            cdIntervals.current.show = setInterval(() => {
                const v = countdown(SHOW_AT);
                if (!v) {
                    setShowCD(showCD);
                    return clearInterval(cdIntervals.current.show);
                }

                setShowCD(v);
            }, 1000);
        }
    }, []);

    useEffect(() => {
        if (data?.gift?.mintableAt) {
            if (cdIntervals?.current?.public)
                clearInterval(cdIntervals.current.public);
            cdIntervals.current.public = setInterval(() => {
                const v = countdown(data?.gift?.mintableAt);
                if (!v) {
                    setPublicCD(publicCD);
                    return clearInterval(cdIntervals.current.public);
                }

                setPublicCD(v);
            }, 1000);
        }

        return () => {
            clearInterval(cdIntervals.current.public);
        };
    }, [data?.gift?.mintableAt]);

    async function setupWeb3() {
        if (!wallet.ethereum) return;
        const _web3 = new Web3(wallet.ethereum);
        setWeb3(_web3);
    }

    useEffect(() => {
        if (web3) connect();

        // eslint-disable-next-line
    }, [web3, wallet.account]);

    useEffect(() => {
        if (contracts) {
            if (refreshInterval.current) {
                clearInterval(refreshInterval.current);
                refreshInterval.current = null;
            }

            getData();
            const seconds = 60;
            refreshInterval.current = setInterval(async () => {
                await getData();
            }, seconds * 1000);
        }

        return () => clearInterval(refreshInterval.current);
    }, [contracts]);

    function connect() {
        const _contracts = {};

        _contracts.gifts = new web3.eth.Contract(
            CONTRACTS.GalakticGifts.ABI,
            CONTRACTS.GalakticGifts.Address[wallet.chainId],
        );
        _contracts.galaktic = new web3.eth.Contract(
            CONTRACTS.GalakticGang.ABI,
            CONTRACTS.GalakticGang.Address[wallet.chainId],
        );

        setContracts(_contracts);
    }

    const handleConnect = () => {
        if (wallet.status === 'connected') {
            return wallet.reset();
        }
        wallet.connect('injected');
    };

    async function getData() {
        if (!wallet.account) return;

        const _data = {};
        _data.balanceGG = await contracts.galaktic?.methods
            ?.balanceOf(wallet.account)
            .call();
        _data.gift = await contracts.gifts?.methods?.gifts?.(2).call();
        _data.balanceGift = await contracts.gifts?.methods
            ?.balanceOf?.(wallet.account, 2)
            .call();
        if (_data.gift) {
            _data.gift.mintableAt = parseInt(_data.gift.mintableAt);

            _data.amount =
                parseInt(_data.balanceGG) / parseInt(_data.gift.ggPer);

            _data.usedMint = parseInt(_data.balanceGift) >= _data.amount;
        }

        setData(_data);
    }

    async function handleMint() {
        setMinting(true);
        try {
            const tx = await contracts.gifts.methods
                .freeMint(2, data.amount)
                .send({
                    from: wallet.account,
                });

            await getData();

            successToast(
                'Anniversary animation has been minted to your address!',
                etherscanTX + tx.transactionHash,
            );
        } catch (error) {
            let message = error.message;
            if (message.includes('Transaction has been reverted by the EVM:')) {
                const pError = JSON.parse(
                    error.message.replace(
                        'Transaction has been reverted by the EVM:',
                        '',
                    ),
                );
                message = await getRevertReason(pError.transactionHash);
            }
            // console.log(JSON.parse(error.message.substring(15).trim()).message);
            errorToast(message);
        }
        setMinting(false);
    }

    async function getRevertReason(txHash) {
        try {
            const tx = await web3.eth.getTransaction(txHash);
            var result = await web3.eth.call(tx, tx.blockNumber);

            result = result.startsWith('0x') ? result : `0x${result}`;

            if (result && result.substr(138)) {
                const reason = web3.utils.toAscii(result.substr(138));
                return reason;
            } else {
                return 'Cannot get reason - No return value';
            }
        } catch (error) {
            return error.message.split('{')[0];
        }
    }

    function successToast(message, link) {
        toast.success(<NotificationMessage title={message} link={link} />, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,

            draggable: true,
            progress: undefined,
        });
    }

    function errorToast(message, link) {
        toast.error(<NotificationMessage title={message} link={link} />, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,

            draggable: true,
            progress: undefined,
        });
    }

    const list = (anchor) => (
        <Box
            sx={{
                width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250,
            }}
            role="presentation"
            onClick={() => setDrawer(false)}
            onKeyDown={() => setDrawer(false)}
        >
            <div className={classes.drawerLogoDiv}>
                <img src={GGLogo} className={classes.drawerLogo} />
            </div>
            <Divider />
            <List>
                {links.map((link, index) => (
                    <ListItem button key={link.name}>
                        <ListItemIcon>
                            <link.icon />
                        </ListItemIcon>
                        <ListItemText primary={link.name} />
                    </ListItem>
                ))}
            </List>
            <Divider />
            <List>
                {socials.map((social, index) => (
                    <ListItem button key={social.name}>
                        <ListItemIcon>
                            <social.icon />
                        </ListItemIcon>
                        <ListItemText primary={social.name} />
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    const now = new Date().getTime() / 1000;
    const publicOpen = data?.gift?.mintableAt && now > data?.gift?.mintableAt;

    if (now < SHOW_AT) {
        return (
            <Box
                sx={{
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                }}
                onClick={playMusic}
            >
                <Box sx={{ height: 20, width: 100 }}>
                    <audio ref={audioRef} autoPlay loop>
                        <source type="audio/mpeg" src={Audio} />
                    </audio>
                </Box>
                <Typography
                    className="title"
                    sx={{ fontSize: 36, color: 'white' }}
                >
                    Wait
                </Typography>
                <Typography
                    className="title"
                    sx={{ fontSize: 36, color: 'white' }}
                >
                    {showCD?.h}:{showCD?.m}:{showCD?.s}
                </Typography>
            </Box>
        );
    }

    return (
        <div className={classes.root}>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className={classes.header}>
                <Hidden mdDown>
                    <img src={GGLogo} className={classes.logo} />
                    <div className={classes.links}>
                        <ul>
                            <li>
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://galakticgang.com/#about"
                                >
                                    About
                                </a>
                            </li>
                            <li>
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://galakticgang.com/#artwork"
                                >
                                    Artwork
                                </a>
                            </li>
                            <li>
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://galakticgang.com/#utility"
                                >
                                    Utility
                                </a>
                            </li>
                            <li>
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://galakticgang.com/#roadmap"
                                >
                                    Roadmap
                                </a>
                            </li>
                            <li>
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://galakticgang.com/#team"
                                >
                                    Team
                                </a>
                            </li>
                            <li>
                                {socials.map((social, idx) => {
                                    return (
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            key={`scidx-${idx}`}
                                            href={social.link}
                                        >
                                            <social.icon
                                                className={classes.socialIcon}
                                            />
                                        </a>
                                    );
                                })}
                            </li>
                        </ul>
                    </div>
                </Hidden>
                <Hidden mdUp>
                    <Button onClick={() => setDrawer(true)}>
                        <Menu />
                    </Button>
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        anchor={'right'}
                        open={drawer}
                        onClose={() => setDrawer(false)}
                    >
                        {list('right')}
                    </Drawer>
                </Hidden>
            </div>
            <div className={classes.navClear} />
            {wallet.status === 'connected' && (
                <Box sx={{ display: 'none' }}>
                    <audio ref={audioRef} autoPlay loop>
                        <source type="audio/mpeg" src={Audio} />
                    </audio>
                </Box>
            )}

            <div className={classes.body}>
                <Grid container spacing={6}>
                    <Grid
                        item
                        sm={12}
                        md={6}
                        classes={{ root: classes.gridAuto }}
                    >
                        <Grow in={true}>
                            <div>
                                {publicCD && (
                                    <div className={classes.center}>
                                        <Typography variant="subtitle1">
                                            <span className={'bold'}>
                                                Mint Countdown
                                            </span>
                                        </Typography>
                                        <Typography variant="h5">
                                            {publicCD.d} Days {publicCD.h}:
                                            {publicCD.m}:{publicCD.s}
                                        </Typography>
                                    </div>
                                )}
                                <div className={clsx(classes.card)}>
                                    <Typography
                                        variant="h4"
                                        className="title"
                                        sx={{ marginBottom: 1 }}
                                    >
                                        Anniversary Gift
                                    </Typography>
                                    <Typography
                                        variant="subtitle1"
                                        sx={{
                                            marginBottom:
                                                wallet.status === 'connected'
                                                    ? 0
                                                    : 2,
                                        }}
                                    >
                                        Celebrate our 1 year anniversary with
                                        this free mint!
                                    </Typography>

                                    {wallet.status === 'connected' &&
                                    !data?.usedMint ? (
                                        <>
                                            <Typography variant="subtitle1">
                                                Claim Amount:{' '}
                                                <span className="bold">
                                                    {data?.amount ?? 0}
                                                </span>
                                            </Typography>
                                        </>
                                    ) : null}
                                    {wallet.status === 'connected' &&
                                    data?.usedMint &&
                                    data.amount > 0 ? (
                                        <>
                                            <Typography variant="subtitle1">
                                                Claimed:{' '}
                                                <span className="bold">
                                                    {data?.balanceGift ?? 0}
                                                </span>
                                            </Typography>
                                        </>
                                    ) : null}
                                    {wallet.status === 'connected' ? (
                                        <>
                                            <Box
                                                className={classes.actions}
                                                sx={{ marginTop: 2 }}
                                            >
                                                <Button
                                                    onClick={() => {
                                                        handleMint();
                                                    }}
                                                    disabled={
                                                        minting ||
                                                        !publicOpen ||
                                                        data?.usedMint
                                                    }
                                                    sx={{
                                                        marginRight: {
                                                            md: 2,
                                                        },
                                                        marginBottom: {
                                                            sm: 2,
                                                            xs: 2,
                                                            md: 0,
                                                        },
                                                    }}
                                                    variant="contained"
                                                >
                                                    {data?.amount === 0
                                                        ? 'Nothing to Claim'
                                                        : data?.usedMint
                                                        ? 'Already claimed'
                                                        : 'Mint your Anniversary Gift!'}
                                                </Button>
                                            </Box>
                                            <Typography sx={{ marginTop: 2 }}>
                                                Every{' '}
                                                <span className="boldish">
                                                    Galaktic Gang NFT
                                                </span>{' '}
                                                gets you{' '}
                                                <span className="boldish">
                                                    1 FREE Anniversary Mint
                                                </span>
                                                !
                                            </Typography>
                                        </>
                                    ) : (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleConnect}
                                        >
                                            Connect Metamask
                                        </Button>
                                    )}
                                    {wallet.status === 'connected' && (
                                        <Typography variant="body1">
                                            Connected with:{' '}
                                            <a
                                                href={`https://etherscan.io/address/${wallet.account}`}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <span className="bold">
                                                    {wallet?.account?.shortenAddress?.()}
                                                </span>
                                            </a>
                                        </Typography>
                                    )}
                                </div>
                            </div>
                        </Grow>
                    </Grid>
                    <Grid item sm={12} md={12} sx={{ paddingBottom: 10 }}>
                        <Box
                            sx={{
                                maxWidth: { xs: '100%', md: 500 },
                                margin: 'auto',
                                borderRadius: 5,
                                overflow: 'hidden',
                            }}
                        >
                            <img src={Slice} className={classes.video} />
                        </Box>
                    </Grid>
                </Grid>
            </div>
            <Box className={classes.footer}>
                <img src={Dots} className={classes.dots} />
                <div>
                    <img src={FooterLogo} />
                    <Typography variant="h5" sx={{ marginBottom: 2 }}>
                        <span className={'underline'}>
                            5555 Positive Freaks n Weirdo
                        </span>{' '}
                        to be recruited
                    </Typography>
                    <Typography variant="subtitle" className={'underline'}>
                        @2021 Chris Dyer NFT. All Rights Reserved
                    </Typography>
                </div>
                <div className={classes.footerLinks}>
                    <ul>
                        <li>
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href="https://galakticgang.com/#about"
                            >
                                About
                            </a>
                        </li>
                        <li>
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href="https://galakticgang.com/#artwork"
                            >
                                Artwork
                            </a>
                        </li>
                        <li>
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href="https://galakticgang.com/#utility"
                            >
                                Utility
                            </a>
                        </li>
                        <li>
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href="https://galakticgang.com/#roadmap"
                            >
                                Roadmap
                            </a>
                        </li>
                        <li>
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href="https://galakticgang.com/#team"
                            >
                                Team
                            </a>
                        </li>
                    </ul>
                </div>
            </Box>
        </div>
    );
}
